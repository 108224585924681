// Generated by Framer (018ad06)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionAWithFX = withFX(motion.a);

const cycleOrder = ["fpN5cT3Lc"];

const serializationHash = "framer-x5A80"

const variantClassNames = {fpN5cT3Lc: "framer-v-1869d3i"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, x: 0, y: 0}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, rotateX: 0, rotateY: 0, scale: 1, skewX: 0, skewY: 0, transition: transition1, x: 0, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "fpN5cT3Lc", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><Link href={"https://sync-master-v1-ba6net.flutterflow.app/"} openInNewTab={false} smoothScroll><MotionAWithFX {...restProps} {...gestureHandlers} __framer__animate={{transition: transition1}} __framer__animateOnce={false} __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1869d3i", className, classNames)} framer-1qh06rn`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fpN5cT3Lc"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-556a66a6-7f4b-4118-b327-ed374e4a0f03, rgb(82, 159, 252))", borderBottomLeftRadius: 20, borderBottomRightRadius: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0dvdGhhbSBCb2xk", "--framer-font-family": "\"Gotham Bold\", \"Gotham Bold Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Get Early Access</motion.p></React.Fragment>} className={"framer-1804s2j"} fonts={["CUSTOM;Gotham Bold"]} layoutDependency={layoutDependency} layoutId={"x1crRKdy4"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></MotionAWithFX></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-x5A80.framer-1qh06rn, .framer-x5A80 .framer-1qh06rn { display: block; }", ".framer-x5A80.framer-1869d3i { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 15px; position: relative; text-decoration: none; width: min-content; }", ".framer-x5A80 .framer-1804s2j { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-x5A80.framer-1869d3i { gap: 0px; } .framer-x5A80.framer-1869d3i > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-x5A80.framer-1869d3i > :first-child { margin-left: 0px; } .framer-x5A80.framer-1869d3i > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 150
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerR9JGUhHoJ: React.ComponentType<Props> = withCSS(Component, css, "framer-x5A80") as typeof Component;
export default FramerR9JGUhHoJ;

FramerR9JGUhHoJ.displayName = "EarlyAccess Button";

FramerR9JGUhHoJ.defaultProps = {height: 40, width: 150};

addFonts(FramerR9JGUhHoJ, [{explicitInter: true, fonts: [{family: "Gotham Bold", source: "custom", url: "https://framerusercontent.com/assets/LwX2vibkB1uUYAebtdYFYGmxV3U.woff2"}]}], {supportsExplicitInterCodegen: true})